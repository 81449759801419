import React, { useState, useEffect } from 'react';
import './formulario.css';
import API from "../../providers/api";
import { Card, CardBody, Row, Col, Input, Button, UncontrolledCollapse, Spinner } from "reactstrap"
import { FaArrowAltCircleRight, FaTrashAlt, FaFileSignature, FaPhotoVideo, FaArrowAltCircleLeft, FaSave, FaSyncAlt } from 'react-icons/fa';
import SignatureCanvas from 'react-signature-canvas'
import imagenFoto from '../../assets/img/imagen-para-foto.png'
import success from '../../assets/img/success.png'
import fail from '../../assets/img/fail.png'
import warning from '../../assets/img/warning.png'
import { alert } from '../../helpers/alert';
import moment from 'moment'
import CanvasFile from "../CanvasFile";

const FormularioCCR = () => {

    const [token, setToken] = useState('')
    const [viewForm, setViewForm] = useState(false)
    const [viewSign, setViewSign] = useState(true)
    const [id, setID] = useState('')
    const [applicant, setApplicant] = useState('')
    const [applicantSignature, setApplicantSignature] = useState('')
    const [identification, setIdentification] = useState('')
    const [value, setValue] = useState(0)
    const [valueLetter, setValueLetter] = useState(0)
    const [state, setState] = useState('')
    const [state_exp, setState_exp] = useState('')
    const [city, setCity] = useState('')
    const [city_exp, setCity_exp] = useState('')
    const [dateCreate, setDateCreate] = useState('')
    const [sign, setSign] = useState('')
    const [signA, setSignA] = useState('')
    const [url, setUrl] = useState('')
    const [urlA, setUrlA] = useState('')
    const [loadingSave, setLoadingSave] = useState(false)
    const [viewButtonSign, setViewButtonSign] = useState(false)
    const [resultSuccess, setResultSuccess] = useState(true)
    const [loadingInit, setLoadingInit] = useState(false)
    const [formSend, setFormSend] = useState(false)
    const [typeSignature, setTypeSignature] = useState('')
    const [font, setFont] = useState('Stalemate')

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const token = queryParameters.get("token")
        if(token != undefined){
            setToken(token)
            API.validateFormCCR(token)
            .then(data => data.json())
            .then(dataJson => {
                if (dataJson.success == true) {
                    const info = dataJson.data
                    if(info.datecreate == null){
                        setID(info.id)
                        setApplicant(`${info.name} ${info.last_name}`)
                        setApplicantSignature(`${info.namecap} ${info.last_namecap}`)
                        setIdentification(new Intl.NumberFormat('es-CO').format(info.identification))
                        setState_exp(info.departamento_exp)
                        setState(info.departamento)
                        setCity_exp(info.municipios_exp)
                        setCity(info.municipio)
                        setDateCreate(new Date(info.datecreate))
                        setViewForm(true)
                        setFormSend(true)
                    }else{
                        setViewForm(false)
                        setResultSuccess(true)
                        setLoadingInit(true)
                        setFormSend(false)
                        const newDate = new Date(info.datecreate)
                        setDateCreate(moment(newDate).format('DD-MM-YYYY'))
                        alert('Warning!', `Esta información fue procesada el día ${moment(info.datecreate).format('DD-MM-YYYY')}`, 'warning', 'Closed');
                    }
                }else{
                    setViewForm(false)
                    setLoadingInit(true)
                    setResultSuccess(false)
                    setFormSend(false)
                }
            })
            .catch((e) => {
                console.log("Error =>", e)
            })
        }
    }, [])

    const handleClear = () => {
        sign.clear()
        setUrl('')
    }

    const handleGenerate = () => {
        setUrl(sign.getTrimmedCanvas().toDataURL('image/png'))
    }

    const handleGenerateA = () => {
        setUrl('')
        //console.log(signA.toDataURL('image/png'));
        setUrlA(signA.toDataURL('image/png'))
    }

    const handleSave = () => {
        if(url == "" && urlA == ""){
            alert('Warning!', 'El documento debe ser firmado para continuar', 'warning', 'Closed');
            return
        }
        setLoadingSave(true)
        setViewButtonSign(false)
        API.saveFormCCR(token, url != "" ? url : urlA != "" ? urlA : '')
            .then(data => data.json())
            .then(dataJson => {
                if (dataJson.success == true) {
                    setViewForm(false)
                    alert('Success!', dataJson.message, 'success', 'Closed');
                }else{
                    alert('Warning!', dataJson.message, 'warning', 'Closed');
                }
                setLoadingSave(false)
                setViewButtonSign(true)
            })
            .catch((e) => {
                console.log("Error =>", e)
            })
    }

    const onchangeTypeSignature = (e) => {
        const value = e.target.value
        setTypeSignature(value)
        if(value == "1"){
            setUrl('')
        }
    }

    return (
        <div className='flex-container'>
            <div>
                <Card style={{ opacity: '0.9' }}>
                    {
                        viewForm ?
                            <CardBody>
                                <div style={{textAlign:'center'}}>
                                    <img style={{width:'100%',maxWidth:'300px'}} src="https://salmoapp.com/portal/wp-content/uploads/2023/01/salmo-logo_horizontal-color.png" />
                                </div>
                                <div style={{padding: '40px 40px 0px 40px'}}>
                                    <p style={{textAlign:'center',fontWeight:'bold',fontSize:'16pt'}}>
                                        AUTORIZACIÓN CONSULTA EN CENTRALES DE RIESGO
                                    </p>

                                    <p style={{textAlign:'justify',fontSize:'14pt'}}>
                                    Usted en su propio nombre o en nombre de la entidad que representa, declara que la información suministrada es verídica y da su consentimiento expreso e irrevocable a <b>SALMO HOLDINGS S.A.S.</b>, o a quien en el futuro haga sus veces como titular del crédito o servicios Solicitados, para:
                                    </p>

                                    <p>
                                        <ol style={{textAlign:'justify',fontSize:'14pt'}}>
                                            <li>
                                                Consultar, en cualquier tiempo, en TransUnion o DataCrédito o en cualquier otra base de datos manejada por un operador de información financiera y crediticia, toda la información relevante para conocer su desempeño como deudor, su capacidad de pago, la viabilidad para entablar o mantener una relación contractual, o para cualquier otra finalidad, incluyendo sin limitarse, la realización de campañas de mercadeo, ofrecimiento de productos y publicidad en general.
                                            </li>
                                            <li>
                                                Reportar a TransUnion y/o DataCrédito y/o a cualquier otra base de datos manejada por un operador datos, tratados o sin tratar, sobre el cumplimiento o incumplimiento de sus obligaciones crediticias, sus deberes legales de contenido patrimonial, sus datos de ubicación y contacto (número de teléfono fijo, número de teléfono celular, dirección laboral, dirección de domicilio y correo electrónico), sus solicitudes de crédito así como otros atinentes a sus relaciones comerciales, financieras y en general socioeconómicas que haya entregado o que consten en registros público, bases de datos públicas o documentos públicos.
                                            </li>
                                            <li>
                                                La autorización anterior no impedirá a usted como usuario o su representado(a), ejercer el derecho a corroborar en cualquier tiempo en <b>SALMO HOLDINGS S.A.S.</b>, en TransUnion y/o DataCrédito o en la central de información de riesgo a la cual se hayan suministrado los datos, que la información suministrada es veraz, completa, exacta y actualizada, y en caso de que no lo sea, a que se deje constancia de su desacuerdo, a exigir la rectificación y a ser informado sobre las correcciones efectuadas.
                                            </li>
                                        </ol>
                                    </p>
                                    <p style={{fontWeight:'bold',marginTop:'50px',fontSize:'14pt;'}}>
                                    <br/>
                                    <img src={url != "" ? url : urlA != "" ? urlA : ''} style={{width:'50%'}} /><br/>
                                    _______________________________<br/>
                                    {applicant}<br/>
                                    C.C. No. {identification}
                                    </p>
                                    <div className='row mb-3'>
                                        <div className='col-lg-2'></div>
                                        <div className='col-lg-8 text-center'>
                                            <label>Selecciona un tipo de firma</label>
                                            <select className='form-control' value={typeSignature} onChange={e => onchangeTypeSignature(e)}>
                                                <option value="">Select</option>
                                                <option value="1">Manuscrito</option>
                                                <option value="2">Automático</option>
                                            </select>
                                        </div>
                                        <div className='col-lg-2'></div>
                                    </div>
                                    {
                                        typeSignature == "2" ?
                                            <>
                                                <div className='row'>
                                                    <div className='col-lg-1'></div>
                                                    <div className='col-lg-10 text-center'>
                                                        <CanvasFile signature={applicantSignature} setSignA={setSignA} font={font} />
                                                    </div>
                                                    <div className='col-lg-1'></div>
                                                </div>
                                                <br/>
                                                <div className='row'>
                                                    <div className='col-lg-1'></div>
                                                    <div className='col-lg-10 text-center'>
                                                        {/* <Button onClick={() => changeFont()}>Change font</Button> */}
                                                        <button className='btn btn-danger' onClick={handleGenerateA}>Obtener firma <FaFileSignature size={20} /></button>
                                                    </div>
                                                    <div className='col-lg-1'></div>
                                                </div>
                                            </>
                                        : typeSignature == "1" ?
                                            viewSign ?
                                                <>
                                                    <div className='row text-center'>
                                                        <div className='col-lg-1'></div>
                                                        <div className='col-lg-10' style={{border:'2px solid black',width:800, height:300}}>
                                                            <label>Escribe tu firma en el siguiente cuadro</label>
                                                            <SignatureCanvas canvasProps={{width:800, height:300, className: 'sigCanvas'}} ref={data=>setSign(data)} />
                                                        </div>
                                                        <div className='col-lg-1'></div>
                                                    </div>
                                                    <br/>
                                                    <div className='row'>
                                                        <div className='col-lg-1'></div>
                                                        <div className='col-lg-10 text-center'>
                                                            <button className='btn btn-dark' onClick={handleClear}>Borrar <FaTrashAlt size={15} /></button>
                                                            <button className='btn btn-danger' onClick={handleGenerate}>Obtener firma <FaFileSignature size={20} /></button>
                                                        </div>
                                                        <div className='col-lg-1'></div>
                                                    </div>
                                                </>
                                            : ''
                                        : ''
                                    }
                                </div>
                                <Row className='mt-2'>
                                    <Col style={{textAlign:'center'}}>
                                    {
                                        loadingSave ?
                                        <><Spinner color='success' /><br/><b style={{color:'#198564'}}>Enviando información... Por favor, espere.</b></>
                                        : <>
                                            <button className='btn btn-success' onClick={handleSave}>Guardar información <FaSave size={20} /> </button>
                                            </>
                                    }
                                    </Col>
                                </Row>
                            </CardBody>
                        :<div className='row mt-4'>
                            <div className='col-lg-1'></div>
                            <div className='col-lg-10 text-center'>
                                <div className='row'>
                                    <div className='col-lg-1'></div>
                                    <div className='col-lg-10 text-center'>
                                        <a href="#">
                                            <img style={{width:'100%',maxWidth:'300px'}} src="https://salmoapp.com/portal/wp-content/uploads/2023/01/salmo-logo_horizontal-color.png" className='logoWidth' />
                                        </a>
                                    </div>
                                    <div className='col-lg-1'></div>
                                </div>
                                {
                                    loadingInit ?
                                        resultSuccess ?
                                            <p style={{fontSize:'14pt',fontWeight:'bold'}}><img src={warning} style={{width:'20%'}} /><br/>Esta información fue procesada el día {dateCreate}.</p>
                                        : <p style={{fontSize:'14pt',fontWeight:'bold'}}><img src={fail} style={{width:'20%'}} /><br/>No ha sido posible procesar la información. Consulte o actualice sus datos en SalmoApp.</p>
                                    : formSend ?
                                            <p style={{fontSize:'14pt',fontWeight:'bold'}}><img src={success} style={{width:'20%'}} /><br/>Información procesada correctamente.</p>
                                        : <Spinner color="success" />
                                }
                            </div>
                            <div className='col-lg-1'></div>
                        </div>
                    }
                </Card>
            </div>
        </div>
    );
}

export default FormularioCCR;