import moment from 'moment';
import URL_ROUTE from '../constants/index';

class Api {
    async validateForm(token) {
        let data = {
            token
        }
        const query = await fetch(`${URL_ROUTE.VALIDATEFORM}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async validateFormCCR(token) {
        let data = {
            token
        }
        const query = await fetch(`${URL_ROUTE.VALIDATEFORMCCR}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async saveForm(token, signature, img_face) {
        let data = {
            token, signature, img_face
        }
        const query = await fetch(`${URL_ROUTE.SAVEFORM}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async saveFormCCR(token, signature) {
        let data = {
            token, signature
        }
        const query = await fetch(`${URL_ROUTE.SAVEFORMCCR}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

}

export default new Api();
