export const HOST = process.env.REACT_APP_HOST_API;
export const VERSION = 'api/v1/';

const APP_URL = {
    VALIDATEFORM: `${HOST}${VERSION}web/form/validateForm`,
    VALIDATEFORMCCR: `${HOST}${VERSION}web/form/validateFormCCR`,
    SAVEFORM: `${HOST}${VERSION}web/form/saveForm`,
    SAVEFORMCCR: `${HOST}${VERSION}web/form/saveFormCCR`,
}

export default APP_URL
