import React, { useState, useEffect } from 'react';
import './formulario.css';
import API from "../../providers/api";
import { Card, CardBody, Row, Col, Input, Button, UncontrolledCollapse, Spinner } from "reactstrap"
import { FaArrowAltCircleRight, FaTrashAlt, FaFileSignature, FaPhotoVideo, FaArrowAltCircleLeft, FaSave, FaSyncAlt } from 'react-icons/fa';
import SignatureCanvas from 'react-signature-canvas'
import imagenFoto from '../../assets/img/imagen-para-foto.png'
import success from '../../assets/img/success.png'
import fail from '../../assets/img/fail.png'
import warning from '../../assets/img/warning.png'
import { alert } from '../../helpers/alert';
import moment from 'moment'
import Webcam from 'react-webcam'
import CanvasFile from "../CanvasFile";

const Formulario = () => {
    const WebcamComponent = () => <Webcam />
    const videoConstraints = {
        width: 400,
        height: 225,
        facingMode: 'user',
    }

    const [picture, setPicture] = useState('')
    const webcamRef = React.useRef(null)
    const capture = React.useCallback(() => {
        const pictureSrc = webcamRef.current.getScreenshot()
        setPicture(pictureSrc)
    })

    const formatCash = Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
    });

    const pathName = window.location.pathname
    const [token, setToken] = useState('')
    const [viewForm, setViewForm] = useState(false)
    const [viewSign, setViewSign] = useState(true)
    const [id, setID] = useState('')
    const [applicant, setApplicant] = useState('')
    const [applicantSignature, setApplicantSignature] = useState('')
    const [identification, setIdentification] = useState('')
    const [email, setEmail] = useState('')
    const [nit, setNit] = useState('')
    const [agreements, setAgreements] = useState('')
    const [quotas, setQuotas] = useState('')
    const [nameQuotas, setNameQuotas] = useState('')
    const [number_account, setNumber_account] = useState('')
    const [type_account, setType_account] = useState('')
    const [bank, setBank] = useState('')
    const [value, setValue] = useState(0)
    const [valueLetter, setValueLetter] = useState(0)
    const [state, setState] = useState('')
    const [state_exp, setState_exp] = useState('')
    const [city, setCity] = useState('')
    const [city_exp, setCity_exp] = useState('')
    const [dateCreate, setDateCreate] = useState('')
    const [sign, setSign] = useState('')
    const [signA, setSignA] = useState('')
    const [url, setUrl] = useState('')
    const [urlA, setUrlA] = useState('')
    const [loadingSave, setLoadingSave] = useState(false)
    const [viewButtonSign, setViewButtonSign] = useState(false)
    const [resultSuccess, setResultSuccess] = useState(true)
    const [loadingInit, setLoadingInit] = useState(false)
    const [formSend, setFormSend] = useState(false)
    const [dateExpiration, setDateExpiration] = useState(moment().add(1, 'year').format('MM/DD/YYYY'))
    const [firtsStep, setFirtsStep] = useState(true)
    const [secondStep, setSecondStep] = useState(false)
    const [finishStep, setFinishStep] = useState(true)
    const [typeSignature, setTypeSignature] = useState('')
    const [font, setFont] = useState('Stalemate')
    const [count, setCount] = useState(0)
    const arrayFonts = ['Arial','stalemate']

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const token = queryParameters.get("token")
        if(token != undefined){
            setToken(token)
            API.validateForm(token)
            .then(data => data.json())
            .then(dataJson => {
                if (dataJson.success == true) {
                    const info = dataJson.data
                    if(info.datecreate == null){
                        setID(info.id)
                        setApplicant(`${info.name} ${info.last_name}`)
                        setApplicantSignature(`${info.namecap} ${info.last_namecap}`)
                        setIdentification(new Intl.NumberFormat('es-CO').format(info.identification))
                        setEmail(info.email)
                        setNumber_account(info.number_account)
                        setType_account(info.type_account)
                        setBank(info.bank)
                        setState_exp(info.departamento_exp)
                        setState(info.departamento)
                        setCity_exp(info.municipios_exp)
                        setCity(info.municipio)
                        setDateCreate(new Date(info.datecreate))
                        setQuotas(info.quota)
                        setAgreements(info.agreement)
                        setNit(info.nit)
                        setValue(new Intl.NumberFormat('es-CO').format(info.value))
                        if(info.type_quota == `QUINCENAL`){
                            setNameQuotas(info.quota > 1 ? `QUINCENALES` : `QUINCENAL`)
                        }else{
                            setNameQuotas(info.quota > 1 ? `MENSUALES` : `MENSUAL`)
                        }
                        setViewForm(true)
                        setFormSend(true)
                    }else{
                        setViewForm(false)
                        setResultSuccess(true)
                        setLoadingInit(true)
                        setFormSend(false)
                        const newDate = new Date(info.datecreate)
                        setDateCreate(moment(newDate).format('DD-MM-YYYY'))
                        alert('Warning!', `Esta información fue procesada el día ${moment(info.datecreate).format('DD-MM-YYYY')}`, 'warning', 'Closed');
                    }
                }else{
                    setViewForm(false)
                    setLoadingInit(true)
                    setResultSuccess(false)
                    setFormSend(false)
                }
            })
            .catch((e) => {
                console.log("Error =>", e)
            })
        }
    }, [])

    const handleClear = () => {
        sign.clear()
        setUrl('')
    }

    const handleGenerate = () => {
        setUrl(sign.getTrimmedCanvas().toDataURL('image/png'))
    }

    const handleGenerateA = () => {
        setUrl('')
        //console.log(signA.toDataURL('image/png'));
        setUrlA(signA.toDataURL('image/png'))
    }

    const handleSave = () => {
        if(url == "" && urlA == ""){
            alert('Warning!', 'El documento debe ser firmado para continuar', 'warning', 'Closed');
            return
        }
        if(picture == ""){
            alert('Warning!', 'Debe tomarse una foto con el documento de identificación para guardar', 'warning', 'Closed');
            return
        }
        setLoadingSave(true)
        setViewButtonSign(false)
        API.saveForm(token, url != "" ? url : urlA != "" ? urlA : '', picture)
            .then(data => data.json())
            .then(dataJson => {
                if (dataJson.success == true) {
                    setViewForm(false)
                    alert('Success!', dataJson.message, 'success', 'Closed');
                }else{
                    alert('Warning!', dataJson.message, 'warning', 'Closed');
                }
                setLoadingSave(false)
                setViewButtonSign(true)
            })
            .catch((e) => {
                console.log("Error =>", e)
            })
    }

    const handleSecondStep = () => {
        if(url == "" && urlA == ""){
            alert('Warning!', 'El documento debe ser firmado para continuar.', 'warning', 'Closed');
            return
        }
        if(city_exp == "" || city_exp == null || city_exp == "null"){
            alert('Warning!', 'Para continuar recuerda diligenciar y/o actualizar toda su información en SALMOAPP.', 'warning', 'Closed');
            return
        }
        setFirtsStep(false)
        setSecondStep(true)
    }

    const backFirtsStep = () => {
        setFirtsStep(true)
        setSecondStep(false)
        setPicture('')
    }

    const onchangeTypeSignature = (e) => {
        const value = e.target.value
        setTypeSignature(value)
        if(value == "1"){
            setUrl('')
        }
    }

    const changeFont = () => {
        if(count != arrayFonts.length){
            setCount(count+1)
        }else{
            setCount(0)
        }
        console.log(arrayFonts[count != arrayFonts.length ? count : 0]);
        setFont(arrayFonts[count != arrayFonts.length ? count : 0])
        //setFont(arrayFonts[0])
    }

    return (
        <div className='flex-container'>
            <div>
                <Card style={{ opacity: '0.9' }}>
                    {
                        viewForm ?
                            firtsStep ?
                                <CardBody>
                                    <div style={{textAlign:'center'}}>
                                        <img style={{width:'100%',maxWidth:'300px'}} src="https://salmoapp.com/portal/wp-content/uploads/2023/01/salmo-logo_horizontal-color.png" />
                                    </div>
                                    <div style={{padding: '40px 40px 0px 40px'}}>
                                        <p style={{textAlign:'center',fontWeight:'bold',fontSize:'16pt'}}>
                                            PRIMER PASO<br/>
                                            CARTA DE INSTRUCCIONES PARA DILIGENCIAMIENTO DE PAGARÉ EN BLANCO No. {('000000'+id).slice(-6)}
                                        </p>

                                        <p style={{textAlign:'justify',fontSize:'14pt'}}>
                                        Yo, <b style={{textDecoration:'underline'}}>{applicant} </b>, mayor de edad, domiciliado y
                                        residente en la ciudad de <b style={{textDecoration:'underline'}}>{city} - {state}</b>, identificado con cédula de
                                        ciudadanía número <b style={{textDecoration:'underline'}}>{identification}</b>, de conformidad con lo establecido en el artículo 622
                                        del Código de Comercio, faculto al señor __________________________________,
                                        mayor de edad, identificado con cédula de ciudadanía número
                                        ___________________________, para que sin previo aviso llene los espacios en blanco
                                        contenidos en el presente pagaré que he otorgado a su orden, cuando exista
                                        incumplimiento de cualquier obligación a mi cargo o se presente cualquier evento que le
                                        permita acelerar las obligaciones, créditos o prestamos que me ha suministrado, de
                                        acuerdo a las siguientes instrucciones:
                                        </p>

                                        <p>
                                            <ol style={{textAlign:'justify',fontSize:'14pt'}}>
                                                <li>
                                                    El lugar del pagó será la ciudad donde se diligencia el pagaré, el lugar y fecha de
                                                    emisión del pagaré serán el lugar y el día en que sea llenado por el señor
                                                    __________________________________o el tenedor legitimo del título.
                                                </li>

                                                <li>
                                                    El monto será igual al valor de todas las obligaciones exigibles que a mi cargo y en
                                                    favor del señor _________________________________________ o el tenedor legitimo
                                                    del título, sea como deudor individual, conjunto o solidario, o de las que sea garante o
                                                    avalista, y en general cualquier suma que adeude por concepto distinto de intereses, que
                                                    existan al momento de ser llenados los espacios en blanco.
                                                </li>

                                                <li>
                                                    El monto por intereses causados y no pagados será el que corresponda por este
                                                    concepto, tanto de intereses de plazo como intereses de mora, el máximo legal vigente.
                                                </li>

                                                <li>
                                                    Aunque el pagaré lo he otorgado y entregado en esta fecha, no debe tenerse esta
                                                    fecha como la de emisión del título. Ésta será la del día en el cual se realice el llenado
                                                    completo del instrumento. Todo para los efectos de contar los plazos de caducidad del
                                                    título y de prescripción de sus acciones.
                                                </li>

                                                <li>
                                                    La forma de vencimiento será a un día cierto, y se establecerá el día en que sean
                                                    llenados los espacios en blanco.
                                                </li>

                                                <li>
                                                    El vencimiento se producirá al día siguiente de su llenado.
                                                </li>

                                                <li>
                                                    Los espacios en blanco se llenarán cuando ocurra una cualquiera de las siguientes
                                                    circunstancias: 7.1. En caso de incumplimiento, retardo o existencia de cualquier causal
                                                    de aceleración en que incurra como otorgante o frente a cualquier obligación pendiente
                                                    sea prendaria o no con el señor ___________________________________________ o el
                                                    tenedor legitimo del título, para lo cual podrá exigir de inmediato el pago de todo lo
                                                    adeudado, como las cuotas pendientes, intereses a plazo, e intereses moratorios sobre
                                                    todo el crédito, mutuo o préstamo de dinero; 7.2. Cuando al deudor se le realice

                                                    intervención cautelar, judicial o administrativa de todo o parte del activo que conforma el
                                                    patrimonio del EL DEUDOR.
                                                </li>

                                                <li>
                                                    El impuesto de timbre será a cargo del otorgante, si hay lugar a él.
                                                </li>

                                                <li>
                                                    Como otorgante autorizo expresamente al señor
                                                    __________________________________ o el tenedor legitimo del título a llenar los
                                                    espacios en blanco correspondiente a mi nombre, número de cédula y domicilio.
                                                </li>

                                                <li>
                                                    Expresamente declaro que he recibido copia de la presente carta de instrucciones y
                                                    del pagaré.
                                                </li>
                                            </ol>
                                        </p>
                                        <p style={{fontWeight:'bold',marginTop:'50px',fontSize:'14pt'}}>
                                        <br/>
                                        <img src={url != "" ? url : urlA != "" ? urlA : ''} style={{width:'80%'}} /><br/>
                                        _______________________________<br/>
                                        {applicant}<br/>
                                        C.C. No. {identification} de {city_exp} - {state_exp}
                                        </p>
                                        {/*Pagaré*/}
                                        <hr style={{border: '2px dotted'}} />
                                        <p style={{textAlign:'center',fontWeight:'bold',fontSize:'16pt'}}>
                                            PAGARÉ No. {('000000'+id).slice(-6)}
                                        </p>

                                        <p style={{textAlign:'justify',fontSize:'14pt'}}>
                                            Yo, <b style={{textDecoration:'underline'}}>{applicant} </b>, mayor de edad, domiciliado (a) en la ciudad de <b style={{textDecoration:'underline'}}>{city} - {state}</b>, identificado (a) con cédula de ciudadanía número <b style={{textDecoration:'underline'}}>{identification}</b>, actuando en mi propio nombre, declaro que PAGARÉ a la ORDEN del señor ______________________________________________________________________________________________________________________ o el tenedor legitimo del título, la suma de ______________________________________________________________________________ ($_____________________________), en el Municipio de <b>BARRANQUILLA</b>, el día ______ del mes _____________ del año ____________ , además de las siguientes sumas de dinero:
                                            <ol style={{textAlign:'justify',fontSize:'14pt'}}>
                                                <li>Por concepto de intereses causados y no pagados la suma de ____________________________________________________________________ ($______________________). </li>
                                                <li>Sobre el monto del capital adeudado según lo indicado en este pagaré, reconoceré intereses de mora a la tasa máxima legalmente autorizada. </li>
                                            </ol>

                                            Ciudad ___________________________________ a los días ___________________ del mes de ______________________ de ______________________.
                                        </p>

                                        <p style={{fontWeight:'bold',marginTop:'50px',fontSize:'14pt'}}>
                                        <br/>
                                        <img src={url != "" ? url : urlA != "" ? urlA : ''} style={{width:'80%'}} /><br/>
                                        _______________________________<br/>
                                        {applicant}<br/>
                                        C.C. No. {identification} de {city_exp} - {state_exp}
                                        </p>

                                        {/*Descuento por libranza*/}
                                        <hr style={{border: '2px dotted'}} />
                                        <p style={{textAlign:'center',fontWeight:'bold',fontSize:'16pt'}}>
                                            AUTORIZACIÓN DE DESCUENTO POR LIBRANZA
                                        </p>
                                        <p style={{textAlign:'right',fontSize:'14pt'}}>
                                            Barranquilla, {moment().format('DD/MM/YYYY')}
                                        </p>
                                        <p style={{textAlign:'left',fontSize:'14pt'}}>
                                            <b>Señores</b><br/>
                                            {agreements}<br/>
                                            {nit}<br/>
                                            Ciudad
                                        </p>
                                        <p style={{textAlign:'left',fontSize:'14pt'}}>
                                            <b>Asunto: Autorización de descuento sobre salario por libranza.</b><br/><br/>
                                        </p>
                                        <p style={{textAlign:'justify',fontSize:'14pt'}}>
                                            Cordial saludo.<br/><br/>
                                            <b>{applicant}</b>, identificado(a) con C.C. No. <b>{identification}</b> de <b>{city_exp} - {state_exp}</b>, por medio de esta solicitud y conforme a la Ley 1527 de 2012<sup>1</sup> me permito informar que estoy en el proceso de aprobación de un crédito de libranza con la entidad <b>SALMO HOLDINGS S.A.S.</b> identificada con NIT. <b>901.693.175-1</b> , en el cual me he comprometido a pagar el valor del crédito por valor de <b>${value}</b> en <b>{quotas} cuotas {nameQuotas}</b> con parte de mi salario<sup>2</sup>. Dichas cuotas serán consignadas en la cuenta <b>{type_account}</b> No. <b>{number_account}</b> del <b>{bank}</b>. Los anteriores descuentos se deberán hacer, incluso durante los periodos de vacaciones y licencias, y en caso de la terminación de la relación laboral, descontarla de la liquidación que por ley me corresponde para pagar la totalidad de dicho préstamo.
                                        </p>
                                        <p style={{textAlign:'justify',fontSize:'14pt'}}>
                                            Esta orden de descuento y pago, se extiende a nuevos empleadores en caso de cambio antes de terminarse de pagar las cuotas antes mencionadas.
                                            <br/>
                                            <br/>
                                            Atentamente,
                                        </p>
                                        <p style={{fontWeight:'bold',marginTop:'50px',fontSize:'14pt'}}>
                                            <br/>
                                            <img src={url != "" ? url : urlA != "" ? urlA : ''} style={{width:'80%'}} /><br/>
                                            _______________________________<br/>
                                            {applicant}<br/>
                                            C.C. No. {identification} de {city_exp} - {state_exp}
                                        </p>
                                        <p style={{marginTop:'50px',fontSize:'8pt'}}>
                                            _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _<br/>
                                            <sup>1</sup> Modificada por la Ley 1902 de 2018.<br/>
                                            <sup>2</sup> El monto mensual del salario que se puede comprometer, no puede afectar el 50% del salario, antes de descuentos de ley (Seguridad Social, embargos, retenciones, etc.).
                                        </p>
                                        <br/>
                                        <div className='row mb-3'>
                                        <div className='col-lg-2'></div>
                                        <div className='col-lg-8 text-center'>
                                            <label>Selecciona un tipo de firma</label>
                                            <select className='form-control' value={typeSignature} onChange={e => onchangeTypeSignature(e)}>
                                                <option value="">Select</option>
                                                <option value="1">Manuscrito</option>
                                                <option value="2">Automático</option>
                                            </select>
                                        </div>
                                        <div className='col-lg-2'></div>
                                    </div>
                                    {
                                        typeSignature == "2" ?
                                            <>
                                                <div className='row'>
                                                    <div className='col-lg-1'></div>
                                                    <div className='col-lg-10 text-center'>
                                                        <CanvasFile signature={applicantSignature} setSignA={setSignA} font={font} />
                                                    </div>
                                                    <div className='col-lg-1'></div>
                                                </div>
                                                <br/>
                                                <div className='row'>
                                                    <div className='col-lg-1'></div>
                                                    <div className='col-lg-10 text-center'>
                                                        <Button onClick={() => changeFont()}>Cambiar firma</Button>
                                                        <button className='btn btn-danger' onClick={handleGenerateA}>Obtener firma <FaFileSignature size={20} /></button>
                                                    </div>
                                                    <div className='col-lg-1'></div>
                                                </div>
                                            </>
                                        : typeSignature == "1" ?
                                            viewSign ?
                                                <>
                                                    <div className='row text-center'>
                                                        <div className='col-lg-1'></div>
                                                        <div className='col-lg-10' style={{border:'2px solid black',width:800, height:300}}>
                                                            <label>Escribe tu firma en el siguiente cuadro</label>
                                                            <SignatureCanvas canvasProps={{width:800, height:300, className: 'sigCanvas'}} ref={data=>setSign(data)} />
                                                        </div>
                                                        <div className='col-lg-1'></div>
                                                    </div>
                                                    <br/>
                                                    <div className='row'>
                                                        <div className='col-lg-1'></div>
                                                        <div className='col-lg-10 text-center'>
                                                            <button className='btn btn-dark' onClick={handleClear}>Borrar <FaTrashAlt size={15} /></button>
                                                            <button className='btn btn-danger' onClick={handleGenerate}>Obtener firma <FaFileSignature size={20} /></button>
                                                        </div>
                                                        <div className='col-lg-1'></div>
                                                    </div>
                                                </>
                                            : ''
                                        : ''
                                    }
                                            <br/>
                                            <div className='row mt-4'>
                                                <div className='col-lg-1'></div>
                                                <div className='col-lg-10 text-center'>
                                                    {
                                                        <button className='btn btn-success' onClick={handleSecondStep}>Continuar al segundo paso <FaArrowAltCircleRight size={15} /></button>
                                                    }
                                                </div>
                                                <div className='col-lg-1'></div>
                                            </div>
                                    </div>
                                </CardBody>
                            : secondStep ?
                                <CardBody>
                                    <div style={{textAlign:'center'}}>
                                        <img style={{width:'100%',maxWidth:'300px'}} src="https://salmoapp.com/portal/wp-content/uploads/2023/01/salmo-logo_horizontal-color.png" />
                                    </div>
                                    <div style={{padding: '40px 40px 0px 40px'}}>
                                        <p style={{textAlign:'center',fontWeight:'bold',fontSize:'16pt'}}>
                                            SEGUNDO PASO<br/>
                                            CARTA DE INSTRUCCIONES PARA DILIGENCIAMIENTO DE PAGARÉ EN BLANCO No. {('000000'+id).slice(-6)}
                                        </p>
                                        <Row>
                                            <Col>
                                                Para finalizar en este último paso es necesario que tomes una foto nítida y con suficiente luz. Esta debe ser de frente donde se visualice tu documento de identidad, así como lo muestra el ejemplo o la ilustración.
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col lg="6">
                                                <label style={{fontWeight:'bold'}}>Ejemplo</label>
                                                <img src= {imagenFoto} style={{width:'100%'}} />
                                            </Col>
                                            <Col lg="6">
                                                <label style={{fontWeight:'bold'}}>Mi foto</label>
                                                {picture == '' ? (
                                                    <Webcam
                                                        audio={false}
                                                        height={225}
                                                        ref={webcamRef}
                                                        width="100%"
                                                        screenshotFormat="image/jpeg"
                                                        videoConstraints={videoConstraints}
                                                    />
                                                    ) : (
                                                    <img src={picture} style={{width:'100%'}} />
                                                )}
                                                {picture != '' ? (
                                                    <button
                                                        onClick={(e) => {
                                                        e.preventDefault()
                                                        setPicture('')
                                                        }}
                                                        className="btn btn-primary"
                                                    >
                                                        Tomar foto nuevamente <FaSyncAlt size={15} />
                                                    </button>
                                                    ) : (
                                                    <button
                                                        onClick={(e) => {
                                                        e.preventDefault()
                                                        capture()
                                                        }}
                                                        className="btn btn-danger"
                                                    >
                                                        Capturar imagen <FaPhotoVideo size={20} />
                                                    </button>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row className='mt-2'>
                                            <Col style={{textAlign:'center'}}>
                                            {
                                                loadingSave ?
                                                <><Spinner color='success' /><br/><b style={{color:'#198564'}}>Enviando información... Por favor, espere.</b></>
                                                : <>
                                                    <button className='btn btn-dark' onClick={backFirtsStep}><FaArrowAltCircleLeft size={15} /> Regresar</button>
                                                    <button className='btn btn-success' onClick={handleSave}>Guardar información <FaSave size={20} /> </button>
                                                    </>
                                            }
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            : ''
                        :<div className='row mt-4'>
                            <div className='col-lg-1'></div>
                            <div className='col-lg-10 text-center'>
                                <div className='row'>
                                    <div className='col-lg-1'></div>
                                    <div className='col-lg-10 text-center'>
                                        <a href="#">
                                            <img style={{width:'100%',maxWidth:'300px'}} src="https://salmoapp.com/portal/wp-content/uploads/2023/01/salmo-logo_horizontal-color.png" className='logoWidth' />
                                        </a>
                                    </div>
                                    <div className='col-lg-1'></div>
                                </div>
                                {
                                    loadingInit ?
                                        resultSuccess ?
                                            <p style={{fontSize:'14pt',fontWeight:'bold'}}><img src={warning} style={{width:'20%'}} /><br/>Esta información fue procesada el día {dateCreate}.</p>
                                        : <p style={{fontSize:'14pt',fontWeight:'bold'}}><img src={fail} style={{width:'20%'}} /><br/>No ha sido posible procesar la información. Consulte o actualice sus datos en SalmoApp.</p>
                                    : formSend ?
                                            <p style={{fontSize:'14pt',fontWeight:'bold'}}><img src={success} style={{width:'20%'}} /><br/>Información procesada correctamente.</p>
                                        : <Spinner color="success" />
                                }
                            </div>
                            <div className='col-lg-1'></div>
                        </div>
                    }
                </Card>
            </div>
        </div>
    );
}

export default Formulario;