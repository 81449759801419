import { BrowserRouter, Routes, Route } from "react-router-dom";
import Formulario from './components/formulario';
import FormularioCCR from './components/formulario/FormularioCCR';

function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route path="validar-consulta-central-riesgo" element={<FormularioCCR />} />
          <Route path="/" element={<Formulario />}>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
