import React, { useRef, useEffect } from "react";
const CanvasFile = (props) => {
  const setSignA = props.setSignA
  const font = props.font
  const canvasSurface = useRef();
  let contextVal = null;
  let canvasVal = null;
  useEffect(() => {
    canvasVal = canvasSurface.current;
    canvasVal.width = canvasVal.clientWidth;
    canvasVal.height = canvasVal.clientHeight;
    contextVal = canvasVal.getContext("2d");
    contextVal.clearRect(0, 0, canvasVal.width, canvasVal.height);
    setSignA(canvasVal)
  }, [font]);

  useEffect(() => {
    addText(
      { text: "", x: 200, y: 100 },
      { fontSize: 50, color: "red", textAlign: "right" },
    );
    addText(
      { text: props.signature, x: 300, y: 50 },
      { fontSize: 80, color: "black", textAlign: "center" },
    );
  }, [font]);
  const addText = (data, style = {}) => {
    const { text, x, y } = data;
    const {
      fontSize = 50,
      fontFamily = font,
      color = "red",
      textAlign = "center",
      textBaseline = "top",
    } = style;
    if(contextVal != null)
    {
      contextVal.beginPath();
      contextVal.textAlign = textAlign;
      contextVal.fillStyle = color;
      contextVal.textBaseline = textBaseline;
      contextVal.fillText(text, x, y);
      contextVal.font = fontSize + "px " + fontFamily;
      contextVal.stroke();
    }
  };

  return (
    <div>
      <canvas style={{border:"1px solid black", width:'100%'}} ref={canvasSurface} {...props}></canvas>
    </div>
  );
}
export default CanvasFile;